/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';

function SchemaComponent({ schema }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  );
}

export default SchemaComponent;