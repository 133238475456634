import React from 'react'
import SchemaComponent from './schema-component'

// Set URL dynamically only if in the browser
const pageUrl = typeof window !== "undefined" ? window.location.href : "";

export default function SchemaOrganization({ page }) {
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "image": page.hero?.cover?.image?.url || page.hero?.cover?.mobileImage?.url,
    "url": pageUrl,
    "logo": "https://images.ctfassets.net/xlzobf9ybr6d/2TflQqcSdKlVQD489hvO3J/b2e022e51360f4025d6221ccdb543e18/logo.png",
    "name": page.name || page.title,
    "description": page.seoMetadata?.description || "No description available",
    "telephone": process.env.NEXT_PUBLIC_COUNTRY === "us" ? "202-697-5222" : "905-564-1118",
    "address": {
      "@type": "PostalAddress",
      streetAddress: process.env.NEXT_PUBLIC_COUNTRY === "us" ? "525 9th St NW, Ste 300" : "5865 Kennedy Rd",
      addressLocality: process.env.NEXT_PUBLIC_COUNTRY === "us" ? "Washington" : "Mississauga",
      addressCountry: process.env.NEXT_PUBLIC_COUNTRY === "us" ? "US" : "CA",
      addressRegion: process.env.NEXT_PUBLIC_COUNTRY === "us" ? "DC" : "ON",
      postalCode: process.env.NEXT_PUBLIC_COUNTRY === "us" ? "20004" : "L4Z 2G3",
    }
  }

  return (
    <SchemaComponent schema={organizationSchema} />
  )
}
