import React from 'react'
import SchemaComponent from './schema-component'

export default function SchemaMenuItem({ item }) {
  
  const href = typeof global !== "undefined" && global.location ? global.location.href : "";
  const menuItemSchema = {
    "@context": "https://schema.org",
    "@type": "MenuItem",
    "name": item.name || undefined, // Use undefined for optional fields
    "description": item.description || undefined, // Use undefined for optional fields
    "image": item.menuImage || undefined, // Use undefined for optional fields
    "url": href,
    "mainEntityOfPage": href,
    "offers": {
      "@type": "Offer",
      "priceCurrency": process.env.NEXT_PUBLIC_COUNTRY === "us" ? "USD" : "CAD",
      "url": href,
      "price": item.cost || undefined, // Use undefined for optional fields
      "availability": "In Stock"
    }
  }

  return (
    <SchemaComponent schema={menuItemSchema}/>
  )
}
