import React from 'react'
import SchemaComponent from './schema-component'

export default function SchemaImage({ image }) {

  const schemaImage = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "contentUrl": image.url || image.menuImage|| undefined,
    "url": image.url || image.menuImage || undefined,
    "name": image.captionAlt || image.title || image.fileName,
    "description": image.description || undefined,
    "width": image.width,
    "height": image.height,
    "thumbnail": {
      "@type": "ImageObject",
      "contentUrl": image.url || image.menuImage || undefined,
      "width": image.width,
      "height": image.height,
    },
    "author": {
      "@type": "Organization",
      "name": "Nando’s PERi-PERi"
    },
    "license": "https://creativecommons.org/licenses/by/4.0/",
    "caption": image.title || image.fileName || image.description,
  }

  return (
    <SchemaComponent schema={schemaImage}/>
  )
}
